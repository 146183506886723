import { set } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { computed } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import DS from 'ember-data';
import { ModelRegistry } from 'ember-data/model';
import RSVP from 'rsvp';

import { Dialog } from '@capacitor/dialog';
import { HTTPResponse } from '@ionic-native/http';
import { task } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import IntlService from 'ember-intl/services/intl';

import { UserModelPayload } from 'mobile-web/adapters/account';
import ENV from 'mobile-web/config/environment';
import { local } from 'mobile-web/decorators/storage';
import { computedLocal, computedSession } from 'mobile-web/lib/computed';
import { Customer } from 'mobile-web/lib/customer';
import { buildOloErrorHandler } from 'mobile-web/lib/errors';
import { fetchOAuthCallback, fetchNativeAppleLogin } from 'mobile-web/lib/hybrid-util';
import { getNameForLoginProvider } from 'mobile-web/lib/login-provider';
import { OnPremiseExperience } from 'mobile-web/lib/on-premise';
import ServeAppleSignin from 'mobile-web/lib/plugins/serve-apple-signin';
import {
  TransitionArg,
  currentTransitionArgs,
  TransitionArgsArray,
  TransitionOptions,
} from 'mobile-web/lib/routing';
import { classes } from 'mobile-web/lib/utilities/classes';
import { addClass, Status } from 'mobile-web/lib/utilities/http';
import { LoginProvider, ChannelSettings } from 'mobile-web/models/bootstrap-data';
import ChannelModel from 'mobile-web/models/channel';
import { LoginProviderUser } from 'mobile-web/models/loyalty-olo-auth-link';
import LoggedInUser, { GlobalUser } from 'mobile-web/models/user';
import BootstrapService from 'mobile-web/services/bootstrap';
import ChallengeService, { ErrorHandler } from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import DeviceService, { SERVE_APP_TOKEN_KEY } from 'mobile-web/services/device';
import ErrorService, { ReadableErr } from 'mobile-web/services/error';
import GlobalEventsService, { GlobalEventName } from 'mobile-web/services/global-events';
import StorageService from 'mobile-web/services/storage';
import WindowService from 'mobile-web/services/window';

import AnalyticsService, { AnalyticsEvents, AnalyticsProperties } from './analytics';
import ArkoseChallengeService, { ArkoseChallengeAction } from './arkose-challenge';
import FeaturesService from './features';

export type User = LoggedInUser | Customer;

export type AttemptedTransition = {
  currentTransitionArgs: TransitionArg[];
};

export default class SessionService extends Service {
  // Service injections
  @service analytics!: AnalyticsService;
  @service bootstrap!: BootstrapService;
  @service challenge!: ChallengeService;
  @service channel!: ChannelService;
  @service device!: DeviceService;
  @service error!: ErrorService;
  @service features!: FeaturesService;
  @service globalEvents!: GlobalEventsService;
  @service intl!: IntlService;
  @service router!: RouterService;
  @service storage!: StorageService;
  @service store!: DS.Store;
  @service window!: WindowService;
  @service arkoseChallenge!: ArkoseChallengeService;

  // Untracked properties
  // Curently we aren't able to compose like `@computedLocal @tracked foo`.
  // So anything that depends on these properties must still use `@computed`.
  @computedLocal localGuestUser?: Customer;
  @computedLocal lastOrderId?: string;

  @computedSession isEditingOrder?: boolean;
  @computedSession editedOrderFromCheckout?: boolean;
  @computedSession savedNewCcCard?: boolean;
  @computedSession savedNewBrandedCard?: boolean;
  @computedSession usedSavedCard?: boolean;
  @computedSession viewedCustomFeesTooltip?: boolean;
  @computedSession loginTracked?: boolean;

  // Tracked properties
  @tracked currentUser?: LoggedInUser;
  @local nextRoute?: TransitionArgsArray;
  @tracked src?: string;
  @tracked restrictedFlow = false;
  @tracked serveAppToken: string | undefined;
  @tracked forcedPasswordReset = false;
  @tracked trackVendorSlug?: string = '';

  // Getters and setters
  @computed('bootstrap.data.guestUser', 'localGuestUser')
  get guestUser(): Customer | undefined {
    const bootstrapGuestUser = this.bootstrap.data?.guestUser;
    const localGuestUser = this.localGuestUser;
    return bootstrapGuestUser ?? localGuestUser;
  }

  get isSavedGuest(): boolean {
    return !!this.currentUser && this.currentUser.isGuest;
  }

  get editAccountUrl(): string | undefined {
    return this.bootstrap.data?.editAccountUrl ?? undefined;
  }

  get registerAccountUrl(): string | undefined {
    return this.bootstrap.data?.registerAccountUrl ?? undefined;
  }

  get isEmbeddedLevelUp(): boolean {
    return this.bootstrap.data?.isEmbeddedLevelUp ?? false;
  }

  get isSsoLogin(): boolean {
    return this.bootstrap.data?.isSsoLogin ?? false;
  }

  /** This property name may be misleading; should be renamed to something like 'isOloAuthUser' since it does not indicate
   *  which provider was used to authenticate *this* session.
   */
  get isOloAuthLogin(): boolean {
    return this.bootstrap.data?.isOloAuthLogin ?? false;
  }

  get enableOloAuthLinking(): boolean {
    return this.bootstrap.data?.enableOloAuthLinking ?? false;
  }

  get isFacebook(): boolean {
    return this.bootstrap.data?.isFacebook ?? false;
  }

  get loginProviderName(): string | undefined {
    return this.bootstrap.data?.loginProviderName ?? undefined;
  }

  get mixpanelUniqueId(): string | undefined {
    return this.bootstrap.data?.mixpanelUniqueId;
  }

  get isRestrictedFlow(): boolean {
    return this.bootstrap.data?.isRestrictedFlow || this.restrictedFlow;
  }

  get isEmbeddedMode(): boolean {
    return this.bootstrap.data?.isEmbeddedMode || this.src?.toLowerCase() === 'fb';
  }

  get isLoggedIn(): boolean {
    return !!this.currentUser && !this.currentUser.isGuest;
  }

  get className(): string {
    const classNames = classes({
      embedded: this.isEmbeddedMode,
      'embedded-levelUp': this.isEmbeddedLevelUp,
      'sso-login': this.isSsoLogin,
      'facebook-referrer': this.isFacebook,
      'logged-in': this.isLoggedIn,
    });
    addClass('body', classNames);
    return classNames;
  }

  get user(): User | undefined {
    return this.currentUser ?? this.guestUser;
  }

  get referrer(): string {
    if (isNone(this.nextRoute)) {
      return '/';
    }
    try {
      const routeName = this.nextRoute[0];
      const params = this.nextRoute.slice(1) as TransitionOptions[];
      return this.router.urlFor(routeName, ...params);
    } catch (e) {
      return '/';
    }
  }

  get currentChannel(): ChannelModel | undefined {
    return this.channel.current;
  }

  get channelSettings(): ChannelSettings | undefined {
    return this.currentChannel?.settings;
  }

  get loginProviders(): LoginProvider[] | undefined {
    return this.currentChannel?.loginProviders;
  }

  get supportsOloLogin(): boolean | undefined {
    return this.channelSettings?.supportsOloLogin;
  }

  get useThirdPartyLogin(): boolean | undefined {
    return this.channelSettings?.useThirdPartyLogin;
  }

  get hasMultipleLoginProviders(): boolean {
    return (this.loginProviders?.length ?? 0) > 1;
  }

  get hasThirdPartyRegistration(): boolean {
    return !!this.useThirdPartyLogin && !!this.registerAccountUrl;
  }

  get hasLoginProvider(): boolean {
    return !!this.loginProviders?.length;
  }

  get signOnAllowed(): boolean {
    return this.hasLoginProvider || (this.supportsOloLogin ?? false);
  }

  get internalSignOnAllowed(): boolean {
    return this.supportsOloLogin || this.useThirdPartyLogin || this.hasMultipleLoginProviders;
  }

  get createAccountAllowed(): boolean {
    return this.supportsOloLogin || this.hasThirdPartyRegistration;
  }

  get isExternalAccount(): boolean {
    return (this.isSsoLogin || this.isEmbeddedMode || this.isFacebook) && !this.isOloAuthLogin;
  }

  get singleSso(): boolean {
    return !this.supportsOloLogin && this.loginProviders?.length === 1;
  }

  get oloAuthProviderSlug(): string {
    return this.loginProviders?.find(lp => lp.isOloAuth)?.slug ?? '';
  }

  get updateOptInValue(): boolean {
    return (
      !this.channelSettings?.hideMarketingOptIn &&
      this.isLoggedIn &&
      this.isOloAuthLogin
    );
  }

  get linkedLoginProviders(): LoginProviderUser[] {
    const loginProviderInfo = this.currentUser?.loginProviderInfo;

    if (loginProviderInfo !== undefined) {
      return loginProviderInfo.linkedLoginProviderUsers;
    }

    return [];
  }

  get unlinkedLoginProviders() {
    return this.currentUser?.loginProviderInfo?.unlinkedLoginProviderUsers ?? [];
  }

  // Lifecycle methods

  // Other methods
  async asyncInit(): Promise<void> {
    if (this.device.isHybrid) {
      const token = await this.device.storageGet(SERVE_APP_TOKEN_KEY);
      if (token) {
        this.serveAppToken = token;
      }
    }
  }

  updateUserOptIn(useDefault: boolean) {
    if (this.isLoggedIn && this.user !== undefined && useDefault) {
      this.user.optIn = !!this.channel.currentCountrySettings?.optIn;
    }
  }

  async validateServeAppToken(): Promise<void> {
    if (this.device.isHybrid && !this.bootstrap.data?.userId) {
      await this.device.storageRemove(SERVE_APP_TOKEN_KEY);
      this.serveAppToken = undefined;
    }
  }

  setGuestUser(userModel: Customer): RSVP.Promise<Customer> {
    set(this, 'localGuestUser', { ...userModel, optInToSms: true });

    // If local storage isn't enabled, the above set will silently fail.
    // We need to use back end session state if that happens, which this
    // collectionAction does.
    return !this.localGuestUser
      ? this.store.collectionAction('user', 'setGuestUser', userModel).then(() => userModel)
      : RSVP.resolve(userModel);
  }

  // The act of reading `this.guestUser` loads guest user data from local storage
  loadGuestUser(): Customer | undefined {
    return this.guestUser;
  }

  async loadUser(userId: EmberDataId): Promise<LoggedInUser> {
    // Models that are only for logged in users but should not block app load if unsuccessful
    const results = await Promise.all([
      this.store.findRecord('user', userId),
      this.safeLoadModels('loyalty-membership', 'mwc.errors.loadMembership'),
      this.safeLoadModels('address', 'mwc.errors.loadAddresses'),
    ]);

    this.currentUser = results[0];

    if (this.isLoggedIn && !this.loginTracked) {
      this.globalEvents.trigger(GlobalEventName.UserLogin, this.serializeUserForGlobalData());
      this.loginTracked = true;
    }

    return results[0];
  }

  safeLoadModels(modelName: keyof ModelRegistry, errorKey: string): Promise<unknown> {
    return this.store.findAll(modelName).catch(e => {
      if (ErrorService.isCloudflareChallenge(e)) {
        throw e;
      }

      this.error.sendExternalError(e);
      this.error.sendUserMessage(this.intl.t(errorKey));
    });
  }

  async setUserFromPayload(user: UserModelPayload): Promise<LoggedInUser> {
    this.store.pushPayload('user', { user });
    if (this.device.isHybrid && user.serveAppToken) {
      await this.device.storageSet(SERVE_APP_TOKEN_KEY, user.serveAppToken);
      this.serveAppToken = user.serveAppToken;
    }
    return this.loadUser(user.id);
  }

  async getExternalLoginUrl(providerSlug: string, linkingMode: boolean) {
    return await this.store
      .adapterFor('user')
      .externalLogin(
        this.referrer,
        providerSlug,
        linkingMode,
        !ENV.isHybrid ? undefined : this.channel.current!.internalName
      );
  }

  async deleteCurrentUser(): Promise<{ success: boolean; pending?: boolean }> {
    if (!this.currentUser) return Promise.reject(new Error('No current user'));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- https://github.com/emberjs/data/issues/2797 there is no public way to make a snapshot
    const snapshot = (this.currentUser as any)._createSnapshot();
    return this.store.adapterFor('user').deleteRecord(this.store, this.currentUser, snapshot);
  }

  storeCurrentRouteAsNextRoute(): void {
    this.nextRoute = currentTransitionArgs(this.router.currentRoute);
  }

  externalLoginWithNextRoute(providerSlug: string, linkingMode = false): void {
    this.storeCurrentRouteAsNextRoute();
    this.externalLogin(providerSlug, linkingMode);
  }

  externalLogin(providerSlug: string, linkingMode: boolean): void {
    taskFor(this.challenge.request).perform(
      () =>
        this.getExternalLoginUrl(providerSlug, linkingMode).then(async result => {
          if (!result) {
            throw new Error(`No response from ${providerSlug}`);
          }

          // Redirect to SSO. This flow will reload the page and therefore spin
          // the app back up, bootstrapping in new data supplied by the server.
          if (result.success) {
            this.analytics.trackEvent(
              AnalyticsEvents.ThirdPartySignIn,
              () => ({
                [AnalyticsProperties.LoginProvider]: providerSlug,
              }),
              { bucket: 'all' }
            );
            if (ENV.isHybrid) {
              // Thankx requires the use of an external browser, not anything in-app
              if (providerSlug === 'thanx' && this.device.isHybridAndroid) {
                window.open(result.data.url, '_blank');
              } else {
                await this.handleHybridOauthFlow(result.data.url, this.referrer, providerSlug);
              }
            } else {
              this.window.location().assign(result.data.url);
            }
          }
        }),
      buildOloErrorHandler('external-login-failure', detail => {
        this.error.sendUserMessage({ detail: typeof detail === 'string' ? detail : undefined });
      })
    );
  }

  async handleNativeAppleSignin(providerSlug: string): Promise<HTTPResponse | undefined> {
    const signInResult = await ServeAppleSignin.handleSignIn();

    const { state } = (await this.store.adapterFor('user').externalLogin('', providerSlug, false))
      .data;

    // https://ololabs.atlassian.net/browse/OLO-24179
    // If a guest deletes their account, and then attempts to sign in again,
    // we will not be provided with their givenName or familyName.
    // Prompt the user for their name if it was missing from the OAuth response.
    if (!signInResult.givenName) {
      const namePrompt = await Dialog.prompt({
        title: 'Contact Info',
        message: 'Please enter your first and last name',
      });

      if (!namePrompt.value) {
        // eslint-disable-next-line no-alert
        alert('No name was provided.');
        return undefined;
      }

      const nameIndex = namePrompt.value.indexOf(' ');
      if (nameIndex < 1) {
        signInResult.givenName = namePrompt.value;
        signInResult.familyName = '-';
      } else {
        signInResult.givenName = namePrompt.value.substring(0, nameIndex);
        signInResult.familyName = namePrompt.value.substring(nameIndex);
      }
    }

    return await fetchNativeAppleLogin(
      signInResult.appleIdToken,
      signInResult.givenName ?? '-',
      signInResult.familyName ?? '-',
      state
    );
  }

  async handleNativeOauthSignin(
    oauthUrl: string,
    providerSlug: string
  ): Promise<HTTPResponse | undefined> {
    let oauthCallbackParts: { state?: string; code?: string } = {};

    if (this.device.isHybridIOS) {
      if (!oauthCallbackParts.state) {
        const oauthTokenString = await new Promise<string>((resolve, reject) =>
          window.plugins.ASWebAuthSession.start(
            this.currentChannel!.internalName,
            oauthUrl,
            resolve,
            reject
          )
        );
        oauthCallbackParts = JSON.parse(atob(oauthTokenString.split(':')[1]));
      }
    } else if (this.device.isHybridAndroid) {
      const inAppBrowser = cordova.InAppBrowser;

      const oauthTokenString = await new Promise<string>((resolve, reject) => {
        const instance = inAppBrowser.open(oauthUrl, '_blank', 'location=yes');
        instance.addEventListener('loadstart', e => {
          if (e && e.url.indexOf(`http://${this.currentChannel!.internalName}:`) === 0) {
            instance.close();
            resolve(e.url.split('http://')[1]);
          }
        });
        instance.addEventListener('exit', () => reject(''));
      });
      oauthCallbackParts = JSON.parse(atob(oauthTokenString.split(':')[1]));
    }

    if (!oauthCallbackParts.state || !oauthCallbackParts.code) {
      throw new Error('Missing data in callback');
    }

    return await fetchOAuthCallback(
      `${window.Olo.hybridAppHost}/user/oauthcallback?state=${oauthCallbackParts.state}&code=${oauthCallbackParts.code}&hybridCallbackComplete=true`,
      providerSlug === 'apple'
    );
  }

  async handleHybridOauthFlow(
    oauthUrl: string,
    referrer: string,
    providerSlug: string
  ): Promise<void> {
    try {
      let oauthCallbackResponse: HTTPResponse | undefined = undefined;
      if (
        this.device.isHybridIOS &&
        providerSlug === 'apple' &&
        (await ServeAppleSignin.isAvailable()).result === 'true'
      ) {
        oauthCallbackResponse = await this.handleNativeAppleSignin(providerSlug);
      } else if (
        (this.device.isHybridAndroid ||
          (this.device.isHybridIOS &&
            (providerSlug === 'thanx' ||
              (this.features.flags['olo-93752-fix-como-hybrid-sign-in'] &&
                providerSlug === 'como')))) &&
        this.device.isCordovaPluginAvailable('cordova-plugin-browsertab')
      ) {
        // @ts-ignore
        const browserTab = cordova.plugins.browsertab as BrowserTab;
        const options =
          providerSlug !== 'thanx' ? { scheme: this.currentChannel!.internalName } : undefined;

        browserTab.openUrl(oauthUrl, options);
        return;
      } else {
        oauthCallbackResponse = await this.handleNativeOauthSignin(oauthUrl, providerSlug);
      }

      await this.handleCompletedOauthFlow(oauthCallbackResponse, referrer);
    } catch (e) {
      this.error.sendUserMessage({ detail: 'Could not log in, please try again.' });
    }
  }

  async handleCompletedOauthFlow(
    oauthCallbackResponse: HTTPResponse | undefined,
    referrer: string
  ): Promise<void> {
    if (oauthCallbackResponse?.data) {
      const { serveAppToken } = JSON.parse(oauthCallbackResponse.data) as {
        serveAppToken: string;
      };
      await this.device.storageSet(SERVE_APP_TOKEN_KEY, serveAppToken);
      this.serveAppToken = serveAppToken;
      if (
        this.device.isHybridIOS &&
        this.device.isCordovaPluginAvailable('cordova-plugin-browsertab')
      ) {
        // @ts-ignore
        const browserTab = cordova.plugins.browsertab as BrowserTab;
        browserTab.close();
      }
    }
    this.window.location().assign(referrer);
  }

  transitionToLogin(): void {
    const loginRoute = 'login';
    let args = currentTransitionArgs(this.router.currentRoute);
    if (args[0] === 'menu.vendor.index') {
      args = currentTransitionArgs(this.router.currentRoute.parent!);
    }
    if (args[0] !== loginRoute) {
      this.nextRoute = args;
      this.router.transitionTo(loginRoute);
    }
  }

  internalLoginTask = task(
    async (email: string, password: string, onSuccess: Action, onError: ErrorHandler) => {
      try {
        let arkoseToken = '';
        if (this.features.flags['use-arkose-challenge-for-internal-login-olo-93155']) {
          arkoseToken = await this.arkoseChallenge.runChallengeAsync(
            ArkoseChallengeAction.INTERNAL_LOGIN
          );
        }

        await taskFor(this.challenge.request).perform(
          async () =>
            this.store
              .adapterFor('user')
              .login(email, password, arkoseToken)
              .then(data => this.setUserFromPayload(data))
              .then(onSuccess),
          e => {
            if (e?.errors?.[0]?.message === 'Password reset required.') {
              this.forcedPasswordReset = true;
            } else {
              const message =
                this.handleCloudflareChallenge(e) || this.intl.t('mwc.errors.loginFailed');
              onError(message);
            }
          }
        );
      } catch (e) {
        onError(e.message || this.intl.t('mwc.errors.loginFailed'));
      }
    }
  );

  async internalLogin(
    email: string,
    password: string,
    onSuccess: Action,
    onError: ErrorHandler
  ): Promise<void> {
    if (!this.internalLoginTask.isRunning) {
      await this.internalLoginTask.perform(email, password, onSuccess, onError);
    }
  }

  handleCloudflareChallenge(e: ReadableErr | undefined): string | undefined {
    if (e?.errors?.[0]?.status === Status.ServiceUnavailable) {
      // cloudflare block, page reload required.
      this.window.location().reload();
      return 'Please wait, reloading the page...';
    }
    return undefined;
  }

  async logout(): Promise<string> {
    if (this.device.isHybrid) {
      await this.device.storageRemove(SERVE_APP_TOKEN_KEY);
    }

    return this.store
      .adapterFor('user')
      .logout()
      .then(logoutResponse => {
        this.currentUser = undefined;
        sessionStorage.clear();
        this.analytics.mixpanelReset();
        return logoutResponse?.redirect_url || '/';
      });
  }

  async refreshServeAppToken(): Promise<void> {
    try {
      if (!this.serveAppToken) {
        return;
      }

      const newToken = (await this.store.adapterFor('user').refreshToken(this.serveAppToken))
        ?.serveAppToken;

      if (newToken) {
        if (this.device.isHybrid) {
          await this.device.storageSet(SERVE_APP_TOKEN_KEY, newToken);
        }
        this.serveAppToken = newToken;
      }
    } catch (e) {}
  }

  serializeUserForGlobalData(): GlobalUser {
    return {
      isLoggedIn: this.isLoggedIn,
      optIn: this.user?.optIn,
      isEmbeddedLevelUp: this.isEmbeddedLevelUp,
      isSsoLogin: this.isSsoLogin,
      isFacebook: this.isFacebook,
      currentCountry: this.channel.currentCountry,
      uniqueId: this.mixpanelUniqueId,
    };
  }

  async getOloAuthApiUrl(useIframe = true): Promise<string> {
    try {
      this.storeCurrentRouteAsNextRoute();
      const result = await this.getExternalLoginUrl(this.oloAuthProviderSlug, false);
      if (!result?.data?.url) return '';
      const url = new URL(result.data.url);
      if (useIframe) url.searchParams.set('use_minimal_layout', 'true');
      return url.toString();
    } catch (e) {
      buildOloErrorHandler('external-login-failure', detail => {
        this.error.sendUserMessage({ detail: typeof detail === 'string' ? detail : undefined });
      })(e);
    }
    return '';
  }

  goToLogin() {
    if (this.internalSignOnAllowed) {
      this.transitionToLogin();
    } else if (this.hasLoginProvider) {
      const provider = this.loginProviders![0];
      this.externalLoginWithNextRoute(provider.slug);
    }
  }

  getLoyaltyTargetName(slug: string | undefined) {
    const linkingTarget = this.loginProviders?.find(lp => lp.slug === slug);
    return getNameForLoginProvider(linkingTarget, 'Loyalty');
  }

  async clearSessionOnPremData() {
    const response = await this.store.adapterFor('session').clearOnPremiseData();
    const newBootstrap = { ...this.bootstrap.data!, response };
    this.store.pushPayload('bootstrap', newBootstrap);
  }

  async setOnPremiseData(table: string | undefined, exp: OnPremiseExperience) {
    const response = await this.store.adapterFor('session').setOnPremiseData(table, exp);
    const newBootstrap = { ...this.bootstrap.data!, response };
    this.store.pushPayload('bootstrap', newBootstrap);
  }

  // Tasks

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    session: SessionService;
  }
}
