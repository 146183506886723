import pick from 'lodash.pick';

import OrderModel from 'mobile-web/models/order';
import UserModel from 'mobile-web/models/user';
import {
  ON_PREMISE_JUNK_FIRST_NAME,
  ON_PREMISE_JUNK_LAST_NAME,
} from 'mobile-web/services/on-premise';

export type PastOnPremiseOrder = {
  displayId: string;
  userId: string;
  timePlaced: string;
  totalCost: number;
  basketProducts: Array<{
    productName: string;
    quantity: number;
  }>;
};

export function toPastOnPremiseOrder(user: UserModel, model: OrderModel): PastOnPremiseOrder {
  return {
    displayId: model.displayId,
    totalCost: model.totalCost || 0,
    userId: user.id,
    timePlaced: `${model.timePlacedUtc}Z`,
    basketProducts: model.basketProducts.map(bp => pick(bp, 'productName', 'quantity')),
  };
}

export function keepPastOrders(user?: UserModel, pastOrders?: PastOnPremiseOrder[]): boolean {
  return !!user && !!pastOrders && pastOrders.every(o => o.userId === user.id);
}

export function isDineInJunkEmail(email: string): boolean {
  return /^dinein\+.*@olo\.com$/.test(email);
}

export function isJunkFirstName(name: string): boolean {
  return name === ON_PREMISE_JUNK_FIRST_NAME;
}

export function isJunkLastName(name: string): boolean {
  return name === ON_PREMISE_JUNK_LAST_NAME;
}

export type ClosedCheckData = {
  readonly start: string;
  readonly continueTime?: string;
  readonly vendorSlug: string;
  readonly pastOrders: PastOnPremiseOrder[];
};

export function closedCheckData(
  vendorSlug: string,
  pastOrders: PastOnPremiseOrder[] = []
): ClosedCheckData {
  return {
    start: new Date().toISOString(),
    vendorSlug,
    pastOrders,
  };
}

export enum OnPremiseExperience {
  ClosedCheck = 'ClosedCheck',
  OpenCheck = 'OpenCheck',
  Default = 'Default',
  PayAtTable = 'PayAtTable',
}
