/* eslint-disable no-use-before-define */
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import DS from 'ember-data';

import pick from 'lodash.pick';

import { OrderRequestType } from 'mobile-web/adapters/order';
import memberAction, { MemberAction } from 'mobile-web/decorators/member-action';
import { CustomField } from 'mobile-web/lib/custom-field';
import dayjs from 'mobile-web/lib/dayjs';
import { DELIVERY_MODE_KEYS, DRIVETHRU_KEY, HandoffModeKey } from 'mobile-web/lib/order-criteria';
import SavedAddressModel from 'mobile-web/models/address';
import BasketModel, {
  CustomFee,
  OnPremiseDetailsPayload,
  pushBasketPayload,
} from 'mobile-web/models/basket';
import BasketProductModel, {
  combineMatchingBasketProductsForEcommerce,
  GlobalBasketProduct,
} from 'mobile-web/models/basket-product';
import BillingComponentModel from 'mobile-web/models/billing-component';
import DispatchStatusModel from 'mobile-web/models/dispatch-status';
import OrderFailureModel from 'mobile-web/models/order-failure';
import Vendor, { GlobalVendor } from 'mobile-web/models/vendor';
import BusService from 'mobile-web/services/bus';
import MwcIntl from 'mobile-web/services/mwc-intl';

import TicketModel from './ticket';

const { attr, belongsTo, hasMany, Model } = DS;

// https://github.com/ololabs/platform/blob/develop/MoboLogic/src/Data%20Structures/Enums.cs
export enum OrderStatus {
  Pending = 'Pending',
  New = 'New',
  FaxPending = 'FaxPending',
  Emailed = 'Emailed',
  InProgress = 'InProgress',
  Closed = 'Closed',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Scheduled = 'Scheduled',
}

export interface GlobalOrder
  extends Pick<
    OrderModel,
    | 'id'
    | 'orderGuid'
    | 'displayId'
    | 'currency'
    | 'currentTotal'
    | 'deliveryCharge'
    | 'deliveryMode'
    | 'handoffDescription'
    | 'isAdvance'
    | 'isFavorite'
    | 'manualFireHandoffInstructions'
    | 'status'
    | 'statusDescription'
    | 'subTotal'
    | 'thanksHandoffInstructions'
    | 'tip'
    | 'timePlacedLocal'
    | 'timePlacedUtc'
    | 'timeReadyLocal'
    | 'timeReadyUtc'
    | 'totalCost'
    | 'utcOffset'
    | 'vendorDiscount'
    | 'vendorName'
    | 'vendorTax'
    | 'vendorTotal'
    | 'guestNewsletterOptIn'
    | 'updateOptInValue'
    | 'newsletterOptIn'
    | 'fees'
    | 'feesTotal'
  > {
  basketProducts: GlobalBasketProduct[];
  vendor: GlobalVendor;
}

type ArrivalPayload = {
  id: EmberDataId;
  hash: string;
  message?: string;
};

export default class OrderModel extends Model {
  @service bus!: BusService;
  @service store!: DS.Store;

  @service mwcIntl!: MwcIntl;

  @DS.attr()
  onPremiseDetails?: OnPremiseDetailsPayload;
  @attr('string')
  orderGuid!: string;
  @attr('string')
  displayId!: string;
  @attr('boolean')
  canCancel!: boolean;
  @attr('boolean')
  canManualFire!: boolean;
  @attr('boolean')
  canModify!: boolean;
  @attr('boolean')
  canUserCheckin!: boolean;
  @attr('string')
  currency?: string;
  @attr('number')
  currentTotal!: number;
  @attr('array')
  customFieldValues!: CustomField[];
  @attr('number')
  deliveryCharge!: number;
  @attr('string')
  deliveryMode!: HandoffModeKey;
  @attr('string')
  feedbackUrl!: string;
  @attr('string')
  groupOrderId!: string;
  @attr('string')
  handoffDescription!: string;
  @attr('boolean')
  isAdvance!: boolean;
  @attr('boolean')
  isFavorite!: boolean;
  @attr('string')
  manualFireHandoffInstructions!: string;
  @attr('string')
  orderHash!: string;
  @attr('string')
  status!: OrderStatus;
  @attr('string')
  statusDescription!: string;
  @attr('number')
  subTotal!: number;
  @attr('string')
  thanksHandoffInstructions!: string;
  @attr('number')
  tip!: number;
  @attr('string')
  timePlacedLocal!: string;
  @attr('string')
  timePlacedUtc!: string;
  /**
   * @summary
   * The vendor local time that the order is expected to be ready, using the
   * vendor's current UTC offset.
   *
   * @deprecated this does not account for DST changes, so `timeReadyUtc` should
   * be used instead.
   */
  @attr('string')
  timeReadyLocal!: string;
  /**
   * @summary
   * The corrected UTC moment in time that the order is expected to be ready
   */
  @attr('date')
  timeReadyUtc?: Date;
  @attr('number')
  totalCost!: number;
  @attr('number')
  utcOffset!: number;
  @attr('number')
  vendorDiscount!: number;
  @attr('string')
  vendorName!: string;
  @attr('number')
  vendorTax!: number;
  @attr('number')
  vendorTotal!: number;
  @attr('boolean', { defaultValue: false })
  guestNewsletterOptIn!: boolean;
  @attr('boolean', { defaultValue: false })
  newsletterOptIn!: boolean;
  @attr('boolean', { defaultValue: false })
  updateOptInValue!: boolean;
  @DS.attr('array')
  fees!: CustomFee[];
  @DS.attr('number')
  feesTotal!: number;
  @attr('boolean', { defaultValue: false })
  canSupportArrival!: boolean;
  @DS.attr('array', { defaultValue: () => [] })
  supportedArrivalMessageHandoffModes!: HandoffModeKey[];
  @belongsTo('vendor', { async: false })
  vendor!: Vendor;
  @hasMany('basket-product', { async: false })
  basketProducts!: DS.ManyArray<BasketProductModel>;
  @hasMany('billing-component', { async: false })
  billingComponents!: DS.ManyArray<BillingComponentModel>;
  @hasMany('dispatch-status')
  dispatchStatuses!: DS.PromiseManyArray<DispatchStatusModel>;
  @belongsTo('order-failure', { async: false })
  orderFailure!: OrderFailureModel;
  @DS.belongsTo('address', { async: false })
  deliveryAddress?: SavedAddressModel;
  @belongsTo('ticket', { async: false })
  ticket?: TicketModel;
  @attr('boolean')
  isTaxExempt!: boolean;
  @attr('string')
  taxExemptAccountId?: string;

  @computed('deliveryMode')
  get isDelivery(): boolean {
    return DELIVERY_MODE_KEYS.includes(this.deliveryMode);
  }

  @computed('deliveryMode')
  get isDriveThru(): boolean {
    return this.deliveryMode === DRIVETHRU_KEY;
  }

  @computed('deliveryMode', 'supportedArrivalMessageHandoffModes')
  get requireArrivalMessage(): boolean {
    return this.supportedArrivalMessageHandoffModes.includes(this.deliveryMode);
  }

  @computed('canManualFire', 'canSupportArrival', 'mwcIntl.now', 'timeReadyLocal')
  get isPastOrder(): boolean {
    return (
      (!this.canManualFire || !this.canSupportArrival) &&
      dayjs(this.mwcIntl.now).subtract(1, 'day').isAfter(this.timeReadyLocal)
    );
  }

  @memberAction<void, BasketModel>({
    type: 'post',
    urlType: OrderRequestType.Reorder,
    before() {
      return {
        sourceId: this.id,
        sourceType: 'Order',
      };
    },
    after: pushBasketPayload,
  })
  reorder!: MemberAction<void, BasketModel>;

  @memberAction<void, BasketModel>({
    type: 'post',
    urlType: OrderRequestType.Edit,
    after: pushBasketPayload,
  })
  edit!: MemberAction<void, BasketModel>;

  @memberAction<ArrivalPayload, void>({
    type: 'post',
    urlType: OrderRequestType.Arrival,
  })
  arrived!: MemberAction<ArrivalPayload, void>;

  get isSplit(): boolean {
    const splitAmount = this.onPremiseDetails?.splitAmount ?? 0;
    const splitItemsLength = this.onPremiseDetails?.splitItems?.length ?? 0;
    const splitBetween = this.onPremiseDetails?.splitBetween ?? 0;
    const payFor = this.onPremiseDetails?.payFor ?? 0;

    return (
      splitAmount > 0 ||
      splitItemsLength > 0 ||
      (splitBetween > 1 && payFor > 0 && payFor < splitBetween)
    );
  }

  // See EcommerceOrderModel
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  serializeForEcommerce() {
    const order = this.serialize() as SerializedOrder;
    const vendor = this.vendor;
    const transactionId = this.id;
    const transactionAffiliation = order.vendorName;
    const transactionTotal = (order.vendorTotal || 0).toFixed(2);
    const transactionTax = (order.vendorTax || 0).toFixed(2);
    const transactionShipping = '0.00';

    const vendorAddress = vendor.address;
    const transactionZipCode = vendorAddress ? vendorAddress.postalCode : undefined;

    const transactionStoreNumber = order.vendor;
    const transactionStoreReference = vendor.externalReference;
    const transactionHandoff = order.handoffDescription;
    const transactionRevenue = (order.subTotal || 0).toFixed(2);
    const transactionCurrency = this.currency;

    const basketProducts = order.basketProducts || [];

    const transactionProducts = combineMatchingBasketProductsForEcommerce(
      basketProducts
        .map(bp => this.store.peekRecord('basket-product', bp))
        .filter(product => product)
        .map(product => product!.serializeForEcommerce(transactionId))
    );

    return {
      transactionId,
      transactionAffiliation,
      transactionTotal,
      transactionTax,
      transactionShipping,
      transactionZipCode,
      transactionStoreNumber,
      transactionStoreReference,
      transactionProducts,
      transactionHandoff,
      transactionRevenue,
      transactionCurrency,
    };
  }

  serializeForGlobalData(): GlobalOrder {
    return {
      ...pick(
        this,
        'id',
        'orderGuid',
        'displayId',
        'currency',
        'currentTotal',
        'deliveryCharge',
        'deliveryMode',
        'handoffDescription',
        'isAdvance',
        'isFavorite',
        'manualFireHandoffInstructions',
        'status',
        'statusDescription',
        'subTotal',
        'thanksHandoffInstructions',
        'tip',
        'timePlacedLocal',
        'timePlacedUtc',
        'timeReadyLocal',
        'timeReadyUtc',
        'totalCost',
        'utcOffset',
        'vendorDiscount',
        'vendorName',
        'vendorTax',
        'vendorTotal',
        'guestNewsletterOptIn',
        'newsletterOptIn',
        'updateOptInValue',
        'fees',
        'feesTotal'
      ),
      basketProducts:
        // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
        this.get('isLoaded') && this.basketProducts.map(bp => bp.serializeForGlobalData()),
      // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
      vendor: this.get('isLoaded') && this.vendor.serializeForGlobalData(),
    };
  }
}

export type EcommerceOrderModel = ReturnType<OrderModel['serializeForEcommerce']>;

interface SerializedOrder {
  orderGuid: string;
  displayId: string;
  canCancel: boolean;
  canManualFire: boolean;
  canModify: boolean;
  canUserCheckin: boolean;
  currentTotal: number;
  customFieldValues: CustomField[];
  deliveryCharge: number;
  deliveryMode: string;
  feedbackUrl: string;
  handoffDescription: string;
  isFavorite: boolean;
  manualFireHandoffInstructions: string;
  orderHash: string;
  status: string;
  statusDescription: string;
  subTotal: number;
  thanksHandoffInstructions: string;
  tip: number;
  timePlacedLocal: string;
  timePlacedUtc: string;
  timeReadyLocal: string;
  totalCost: number;
  utcOffset: number;
  vendorDiscount: number;
  vendorName: string;
  vendorTax: number;
  vendorTotal: number;
  guestNewsletterOptIn: boolean;
  newsletterOptIn: boolean;
  updateOptInValue: boolean;
  canSupportArrival: boolean;

  vendor: number | string;
  basketProducts: Array<number | string>;
  billingComponents: Array<number | string>;
  dispatchStatuses: Array<number | string>;
  orderFailure: number | string;
}
