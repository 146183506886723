import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import DS from 'ember-data';

import { enqueueTask, TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import pick from 'lodash.pick';

import savedAttributes, { PrimitiveAttributes } from 'mobile-web/decorators/saved-attributes';
import { CustomizedProductModel } from 'mobile-web/lib/menu';
import isSome from 'mobile-web/lib/utilities/is-some';
import { numberOr0 } from 'mobile-web/lib/utilities/numeric';
import AnalyticsService, {
  AddToCartMethod,
  AnalyticsEvents,
  AnalyticsProperties,
} from 'mobile-web/services/analytics';
import BasketService from 'mobile-web/services/basket';
import ChallengeService from 'mobile-web/services/challenge';
import ChannelService from 'mobile-web/services/channel';
import FeaturesService from 'mobile-web/services/features';
import { ProductClickFrom } from 'mobile-web/services/global-data';
import OnPremiseService from 'mobile-web/services/on-premise';
import VendorService from 'mobile-web/services/vendor';

import BasketModel from './basket';
import BasketChoiceModel from './basket-choice';
import OptionGroupModel from './option-group';
import ProductModel, { GlobalProduct } from './product';
import Vendor from './vendor';

export type ChoiceCustomFieldValue = {
  id: EmberDataId;
  customFieldId: EmberDataId;
  choiceId: EmberDataId;
  value: string;
  label: string;
};

interface EcommerceProduct {
  id: string;
  sku: string;
  name: string;
  category: string;
  price: string;
  quantity: string;
}

interface EcommerceProductRaw extends Omit<EcommerceProduct, 'price' | 'quantity'> {
  price: number;
  quantity: number;
}

export interface GlobalBasketProduct
  extends Pick<
    // eslint-disable-next-line no-use-before-define
    BasketProductModel,
    | 'productName'
    | 'categoryName'
    | 'recipientName'
    | 'specialInstructions'
    | 'quantity'
    | 'menuViewId'
    | 'totalCost'
    | 'unitCost'
    | 'customizeDescription'
  > {
  product?: GlobalProduct;
}

export default class BasketProductModel extends DS.Model {
  @service analytics!: AnalyticsService;
  @service('basket') basketService!: BasketService;
  @service('vendor') vendorService!: VendorService;
  @service challenge!: ChallengeService;
  @service features!: FeaturesService;
  @service channel!: ChannelService;
  @service onPremise!: OnPremiseService;

  @DS.attr('string')
  productName!: string;
  @DS.attr('string')
  brandName!: string;
  @DS.attr('string')
  categoryName!: string;
  @DS.attr('string')
  recipientName!: string;
  @DS.attr('string')
  specialInstructions!: string;
  @DS.attr('number')
  quantity!: number;
  @DS.attr('number')
  menuViewId!: number;
  @DS.attr('number')
  totalCost!: number;
  @DS.attr('number')
  unitCost!: number;
  @DS.attr('boolean')
  upsell!: boolean;
  @DS.attr('string')
  customizeDescription!: string;
  @DS.attr('array', { defaultValue: () => [] })
  customValues!: ChoiceCustomFieldValue[];

  // ensureVendorLoaded is called in application route model hook, so `!` is safe here
  @DS.belongsTo('vendor', { async: false })
  vendor!: Vendor;

  // We always load basket products when we get the basket from BasketsController.GetBasket in the back end.
  // That endpoint returns a sideload model with basket, basket-products and basket-choices.
  // So if we have a basket-product, we can know we have a basket and all basket-choices.
  @DS.belongsTo('basket', { async: false })
  basket!: BasketModel;
  @DS.hasMany('basket-choice', { async: false })
  basketChoices!: DS.ManyArray<BasketChoiceModel>;

  @DS.belongsTo('product')
  product!: DS.PromiseObject<ProductModel>;

  @savedAttributes
  // eslint-disable-next-line no-use-before-define
  savedAttributes!: PrimitiveAttributes<BasketProductModel>;

  @alias('product.optionGroups')
  optionGroups?: DS.PromiseManyArray<OptionGroupModel>;

  get canSubmit(): boolean {
    return (
      this.quantity > 0 &&
      (this.product.content?.firstLevelOptionGroupModels.every(og => og.canSubmit) ?? true)
    );
  }

  /**
   * Indicates that the basket-product is able to have its quantity increased as
   * part of a quick-add.
   */
  get canQuickAdd(): boolean {
    return (
      !!this.product.content?.quickAddSupported &&
      isEmpty(this.recipientName) &&
      isEmpty(this.specialInstructions) &&
      this.quantity <
        Math.min(
          this.product.content?.maximumQuantity ?? 100,
          this.channel.settings?.basketProductMaxQuantity ?? 100
        )
    );
  }

  @computed('product.baseCost', 'basketChoices.@each.quantity')
  get estimatedUnitCost(): number {
    const baseCost = this.product.get('baseCost') ?? 0;
    const ogs = this.product.get('firstLevelOptionGroupModels');
    return ogs?.reduce((sum, og) => sum + og.selectedChoiceCost, baseCost) ?? baseCost;
  }

  setQuantityBasedOnChoiceQuantities(): void {
    const firstQuantityOg = this.product.content?.primaryProductQuantityOptionGroup;
    if (firstQuantityOg) {
      this.quantity = firstQuantityOg.aggregateQuantity;
    }
  }

  serializeForEcommerce(this: BasketProductModel, orderId: EmberDataId): EcommerceProductRaw {
    const sku = this.belongsTo('product').id() + '';
    const name = this.productName;
    const category = this.categoryName;
    const price = this.unitCost ? this.unitCost : 0.0;
    const quantity = this.quantity;
    const id = orderId + '';

    return {
      id,
      sku,
      name,
      category,
      price,
      quantity,
    };
  }

  serializeForGlobalData(this: BasketProductModel, previousQuantity?: number): GlobalBasketProduct {
    const productId = this.belongsTo('product').id();
    const productExists = isSome(productId) && isSome(this.store.peekRecord('product', productId));
    let globalProduct = { id: productId } as GlobalProduct | undefined;
    if (productExists) {
      globalProduct = this.product.content?.serializeForGlobalData();
    }

    let quantity = this.quantity;
    if (previousQuantity !== undefined && previousQuantity > -1) {
      // get absolute value of quantity in case we are firing v1.removeFromCart instead of v1.addToCart
      quantity = Math.abs(this.quantity - previousQuantity);
    }

    const model = {
      ...pick(
        this,
        'productName',
        'categoryName',
        'recipientName',
        'specialInstructions',
        'quantity',
        'menuViewId',
        'totalCost',
        'unitCost',
        'customizeDescription'
      ),
      // eslint-disable-next-line ember/no-get, ember/classic-decorator-no-classic-methods
      product: globalProduct,
      quantity,
    };
    return model;
  }

  saveTask = taskFor(this.saveInstance);
  @enqueueTask *saveInstance(options: {
    eventName: AddToCartMethod;
    clickFrom?: ProductClickFrom;
    onSuccess?: Action;
  }): TaskGenerator<void> {
    yield taskFor(this.challenge.request).perform(async () => {
      if (!this.basketService.basket) {
        await this.basketService.createBasket();
      }

      const addtoCartType = this.get('isNew') ? 'Add' : 'Update';

      await this.save();

      let requiredCount = 0;
      let defaultCount = 0;
      let imageCount = 0;
      let priceSum = 0;
      const priceOfModifier: number[] = [];
      let totalModifiers = 0;

      this.product.get('optionGroups')?.forEach(og => {
        if (og.isRequired) requiredCount++;

        og.choices.forEach(ch => {
          if (og.isRequired && ch.get('isDefault')) defaultCount++;
          if (ch.image?.filename) imageCount++;
          const priceDifference: number = ch.get('priceDifference') ?? 0;
          priceSum += priceDifference;
          if (priceDifference > 0) {
            priceOfModifier.push(priceDifference);
          }

          totalModifiers++;
        });
      });

      this.analytics.trackEvent(AnalyticsEvents.AddToCart, () => ({
        [AnalyticsProperties.ProductName]: this.product.get('name'),
        [AnalyticsProperties.ProductCategory]: this.product.get('category')?.name,
        [AnalyticsProperties.ProductQuantity]: this.quantity,
        [AnalyticsProperties.ProductBasePrice]: this.product.get('baseCost'),
        [AnalyticsProperties.ProductAvailableOptionGroupCount]: numberOr0(
          this.optionGroups?.length
        ),
        [AnalyticsProperties.AddToCartMethod]: options.eventName,
        [AnalyticsProperties.AddToCartType]: addtoCartType,
        [AnalyticsProperties.HasVisibleCalories]:
          isSome(this.vendor.settings.showCalories) && isSome(this.product.get('calorieLabel')),
        [AnalyticsProperties.VisibleLabels]: this.product.get('labels')?.map(l => l.name),
        [AnalyticsProperties.HasProductImages]: !isEmpty(this.product.get('images')),
        [AnalyticsProperties.HasCategoryImages]: !isEmpty(this.product.get('category')?.images),
        [AnalyticsProperties.IsFeatured]: this.product.get('isFeatured'),
        [AnalyticsProperties.IsSingleUse]: this.product.get('isSingleUse'),
        [AnalyticsProperties.IsRecentItem]: options.clickFrom === ProductClickFrom.RecentItem,
        [AnalyticsProperties.QuickAddSupported]: this.product.get('quickAddSupported'),
        [AnalyticsProperties.NumberOfTotalMods]: totalModifiers,
        [AnalyticsProperties.NumberOfRequiredMods]: requiredCount,
        [AnalyticsProperties.NumberOfDefaultedMods]: defaultCount,
        [AnalyticsProperties.NumberOfPricedMods]: priceOfModifier,
        [AnalyticsProperties.NumberOfImageMods]: imageCount,
        [AnalyticsProperties.NumberOfSelectedMods]: this.basketChoices.filter(
          bc => bc.isSelected === true && !bc.isNew
        ).length,
        [AnalyticsProperties.SumOfModPrices]: priceSum,
      }));

      if (this.onPremise.hasOpenCheck && !this.onPremise.openCheckRoundStarted) {
        this.onPremise.openCheckRoundStarted = true;
        this.analytics.trackEvent(AnalyticsEvents.OpenCheckRoundStarted, () => ({
          [AnalyticsProperties.OpenCheckRoundsOrdered]: this.onPremise.openCheckRoundsOrdered ?? 0,
        }));
      }

      if (this.product.get('isRecentItem')) {
        this.vendorService.refreshRecentItemsCategoryIfVisible.perform();
      }

      options.onSuccess?.();
    });
  }
}

// Needed because GA does not like when multiple products have the same SKU (applies to analytics.js and ga.js)
// https://developers.google.com/analytics/devguides/collection/gajs/methods/gaJSApiEcommerce#_additem:~:text=This%20method%20performs%20no%20additional%20calculations%2C%20such%20as%20quantity%20calculations.
export function combineMatchingBasketProductsForEcommerce(
  basketProducts: EcommerceProductRaw[]
): EcommerceProduct[] {
  const combinedBasketProducts = basketProducts.reduce<{ [_: string]: EcommerceProductRaw }>(
    (acc, bp) => {
      if (!bp) return acc;

      const cur = acc[bp.sku];

      // No basket product with this ID, store it an continue
      if (!cur) {
        acc[bp.sku] = bp;
        return acc;
      }

      // Combine this basket product with the last one
      // `price` is a per-unit price, and because different instances of the same SKU
      // can have different prices (due to modifiers being added), we do our best
      // and find an average price per unit so that the Google Analytics revenue is correct.
      // Calculate the new average price by taking the current price and quantity for this item,
      // multiplying it out to the total cost, adding the total cost of the new item, and averaging
      // both the existing total cost and the new total cost by the new quantity and rounding to two decimal places.
      const newQuantity = cur.quantity + bp.quantity;
      const newAveragePrice =
        Math.round(100 * ((cur.price * cur.quantity + bp.price * bp.quantity) / newQuantity)) / 100;
      acc[bp.sku] = {
        ...cur,
        price: newAveragePrice,
        quantity: newQuantity,
      };

      return acc;
    },
    {}
  );

  return Object.keys(combinedBasketProducts).map(k => ({
    ...combinedBasketProducts[k],
    quantity: combinedBasketProducts[k].quantity.toFixed(0),
    price: combinedBasketProducts[k].price.toFixed(2),
  }));
}

export function isBasketProduct(product: CustomizedProductModel): product is BasketProductModel {
  return 'totalCost' in product;
}
