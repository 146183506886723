import { computed, set } from '@ember/object';
import { none, alias } from '@ember/object/computed';
import RouterService from '@ember/routing/router-service';
import Service, { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import Store from 'ember-data/store';

import { task } from 'ember-concurrency';
import IntlService from 'ember-intl/services/intl';

import dayjs from 'mobile-web/lib/dayjs';
import { getHandoffLabel } from 'mobile-web/lib/order-criteria';
import { Calendar, CalendarType, Weekday } from 'mobile-web/lib/time/calendar';
import { hoursForDay, ScheduleAvailabilityKeywords } from 'mobile-web/lib/time/weekly-schedule';
import Vendor, { getShortOverrideDesc } from 'mobile-web/models/vendor';
import BasketService from 'mobile-web/services/basket';
import FeaturesService from 'mobile-web/services/features';
import StorageService from 'mobile-web/services/storage';

import ErrorService from './error';
import OrderCriteriaService from './order-criteria';
import SessionService from './session';

const days = Object.values(Weekday);
const schedulePrefix = 'mwc.vendorInfo.scheduleAvailability.';
export default class VendorService extends Service {
  // Service injections
  @service basket!: BasketService;
  @service error!: ErrorService;
  @service intl!: IntlService;
  @service orderCriteria!: OrderCriteriaService;
  @service router!: RouterService;
  @service storage!: StorageService;
  @service store!: Store;
  @service session!: SessionService;
  @service features!: FeaturesService;

  // Untracked properties
  _vendor?: Vendor;

  // Tracked properties

  // Getters and setters
  @computed('_vendor')
  get vendor(): Vendor | undefined {
    return this._vendor;
  }
  set vendor(value: Vendor | undefined) {
    set(this, '_vendor', value);
    if (value) {
      this.storage.set('lastVendorSlug', value.slug);
    }
  }

  get isMultiConcept(): boolean {
    return this.vendor?.isMultiConcept ?? false;
  }

  // Moved from <VendorInfo>, originally added in PR #1987.
  // Deserves an entire rewrite, but now is not the time. Sorry.
  get availabilityText(): string {
    if (!this.vendor) {
      return '';
    }
    if (this.vendor.overrideReason) {
      return getShortOverrideDesc(this.vendor.overrideReason, this.intl);
    }
    const schedule = this.vendor.weeklySchedule;
    let weekSchedule =
      schedule.calendars.find(c => c.scheduleDescription === CalendarType.Business) ||
      schedule.calendars[0];
    let mode: string | undefined;
    if (!this.orderCriteria.criteria.isDefault) {
      const oc = this.orderCriteria.criteria;
      mode = getHandoffLabel(oc.handoffMode, this.store);
      const ocWeekSchedule = schedule.calendars.find(c => c.scheduleDescription === mode);
      if (ocWeekSchedule) {
        weekSchedule = ocWeekSchedule;
      } else if (oc.handoffMode !== 'CounterPickup') {
        // When not using a handoff specific schedule, do not display
        // handoff specific message - except with Counter Pickup,
        // as it derives from the Business schedule
        mode = undefined;
      }
    }

    // All times based around vendor time zone
    const userTimeAtStore = dayjs().utcOffset(this.vendor.utcOffset);
    let currentWeekDay = schedule.currentWeekDay;
    if (userTimeAtStore.day() !== days.indexOf(currentWeekDay)) {
      // This should never happen. The day at the store must be the day for the user
      // at the store in the store's timezone.
      // Could send at around midnight for user if not refreshed,
      // so want to restore to "fresh" value if required
      this.error.sendExternalError(new Error('Vendor current weekday incongruent with user time'));
      currentWeekDay = days[userTimeAtStore.day()];
    }

    // currentWeekDay is already relative to vendor time zone
    const hours = hoursForDay(currentWeekDay, weekSchedule);

    if (hours === ScheduleAvailabilityKeywords.Closed) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayClosed', { mode })
        : this.intl.t(schedulePrefix + 'todayClosed');
    }

    let start = '',
      end = '',
      startTime = '',
      endTime = '';
    if (hours !== ScheduleAvailabilityKeywords.AllDay) {
      start = hours.split('-')[0];
      end = hours.split('-')[1];
      startTime = convertToTime(userTimeAtStore.format(), start);
      endTime =
        // Ending at midnight is actually 12am on the next day
        end === ScheduleAvailabilityKeywords.Midnight
          ? convertToTime(userTimeAtStore.add(1, 'd').format(), end)
          : convertToTime(userTimeAtStore.format(), end);
    }

    // Workaround for MWC-3921
    // to support multi-day availability spans
    if (hours.includes(',')) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayMultiRange', { mode, range: hours })
        : this.intl.t(schedulePrefix + 'todayMultiRange', { range: hours });
    } else if (hours.includes('(') || dayjs(startTime).unix() > dayjs(endTime).unix()) {
      // Two day spans wrapped in parentheses when hour overlap
      // Otherwise, end hour must be < start hour
      start = midnightToLowerCase(start);
      end = midnightToLowerCase(end);
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNowVerbose', { mode, start, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    }

    if (
      hours === ScheduleAvailabilityKeywords.AllDay ||
      (dayjs().isBetween(startTime, endTime) && end === ScheduleAvailabilityKeywords.Midnight)
    ) {
      return this.consecutiveDayAvailabilityMessaging(
        weekSchedule,
        mode,
        currentWeekDay,
        start,
        end
      );
    }

    start = midnightToLowerCase(start);
    end = midnightToLowerCase(end);
    if (dayjs().isBetween(startTime, endTime)) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', { mode, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    } else if (dayjs().isBefore(startTime)) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffBeforeOpening', { mode, start })
        : this.intl.t(schedulePrefix + 'beforeOpening', { start });
    }
    return mode
      ? this.intl.t(schedulePrefix + 'handoffNowTodayClosed', { mode })
      : this.intl.t(schedulePrefix + 'nowTodayClosed');
  }

  private get isOrWasLoggedIn(): boolean {
    return this.session.isLoggedIn || this.storage.wasLoggedIn || false;
  }
  get recentItemsFlag(): undefined | 'recent-items' | 'recent-items-elevated' {
    if (this.isOrWasLoggedIn) {
      const variation = this.features.flags['abtest-recent-items-replace-recent-orders'] as string;
      return variation === 'recent-items' || variation === 'recent-items-elevated'
        ? variation
        : undefined;
    }
    return undefined;
  }
  private get recentItemLimitFF(): number | undefined {
    return (this.features.flags['abtest-recent-items-limit'] as number) ?? 100;
  }
  get maxRecentItems(): number | undefined {
    // currently redundant, but when the recentItemsFlag property is removed, should still check if logged in
    return this.isOrWasLoggedIn && this.recentItemsFlag ? this.recentItemLimitFF : undefined;
  }

  @none('vendor')
  vendorIsNotLoaded!: boolean;

  @alias('vendor.currency')
  currency?: string;

  @alias('vendor.slug')
  vendorSlug?: string;

  // Lifecycle methods
  constructor() {
    super(...arguments);

    this.router.on('routeDidChange', () => {
      const targetName = this.router.currentRouteName;

      const isMenuRoute = targetName.indexOf('menu') > -1;
      const isRewardsSearchRoute = targetName === 'rewards-search';
      const isThankYouRoute = targetName === 'thank-you';
      const isPayAtTableRoute = targetName.indexOf('pay') > -1;
      const isDispatchStatusRoute = targetName === 'dispatch-status';

      if (
        !isMenuRoute &&
        !isRewardsSearchRoute &&
        !isThankYouRoute &&
        !isPayAtTableRoute &&
        !isDispatchStatusRoute &&
        isNone(this.basket.basket)
      ) {
        if (this.vendor) {
          set(this, 'vendor', undefined);
        }
      }
    });
  }

  // Other methods
  async ensureVendorLoaded(vendorSlug: string): Promise<Vendor> {
    const slug = vendorSlug || this.vendorSlug;
    const currentVendor = this.vendor;

    const setupVendor = (vendor: Vendor) => {
      if (this.storage.lastVendorSlug !== slug) {
        this.storage.ignorePrecheckError = false;
      }

      this.basket.onVendorUpdate(vendor.id);
      set(this, 'vendor', vendor);

      return vendor;
    };

    const store = this.store;

    let vendor: Vendor | undefined;
    if (!isNone(currentVendor) && currentVendor.slug === slug) {
      vendor = await store.findRecord('vendor', currentVendor.id);
    }
    if (!vendor) {
      vendor = store.peekAll('vendor').find(v => v.slug === slug);
    }

    // Check to make sure that the vendor has its products and catogories loaded
    // because side loading vendors via orders may cause some of the data to be missing locally
    // vendor.hasMany('products').ids().length === 0
    if (!vendor || vendor.hasMany('products').ids().length === 0) {
      try {
        vendor = await store.queryRecord('vendor', { slug });
      } catch (e) {
        if (e.errors?.length > 0 && e.errors[0].status === '404' && slug) {
          this.removeFromVendorHistory(slug);
        }
        throw e;
      }
    }
    return setupVendor(vendor);
  }

  removeFromVendorHistory(slug: string) {
    if (this.storage.vendorHistory) {
      this.storage.vendorHistory = this.storage.vendorHistory.filter(n => n.slug !== slug);
    }
  }

  //Moved from PR #1987
  consecutiveDayAvailabilityMessaging(
    weekSchedule: Calendar,
    mode: string | undefined,
    currentWeekDay: Weekday,
    start: string,
    end: string
  ) {
    // Checking for 24/7 availability
    if (
      weekSchedule.schedule.filterBy('description', ScheduleAvailabilityKeywords.AllDay).length ===
      days.length
    ) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffOpen24/7', { mode })
        : this.intl.t(schedulePrefix + 'open24/7');
    }

    const index = days.indexOf(currentWeekDay);
    let pointer = -1;
    for (let i = 1; i < days.length; i++) {
      pointer = (i + index) % days.length;
      const dayHours = hoursForDay(days[pointer], weekSchedule);
      if (
        dayHours !== ScheduleAvailabilityKeywords.AllDay &&
        (dayHours === ScheduleAvailabilityKeywords.Closed ||
          dayHours.split('-')[0] !== ScheduleAvailabilityKeywords.Midnight)
      ) {
        pointer = (i - 1 + index) % days.length;
        break;
      } else if (dayHours !== ScheduleAvailabilityKeywords.AllDay) {
        break;
      }
    }

    start = midnightToLowerCase(start);
    end = midnightToLowerCase(end);
    const day = days[pointer];
    const lastConsecutiveOpenDayHours = hoursForDay(day, weekSchedule);
    if (day === currentWeekDay) {
      if (lastConsecutiveOpenDayHours === ScheduleAvailabilityKeywords.AllDay) {
        return mode
          ? this.intl.t(schedulePrefix + 'handoffTodayOpenAllDay', { mode })
          : this.intl.t(schedulePrefix + 'todayOpenAllDay');
      }
      return mode
        ? this.intl.t(schedulePrefix + 'handoffTodayOpenNow', { mode, end })
        : this.intl.t(schedulePrefix + 'todayOpenNow', { start, end });
    }
    if (lastConsecutiveOpenDayHours === ScheduleAvailabilityKeywords.AllDay) {
      return mode
        ? this.intl.t(schedulePrefix + 'handoffConsecutiveAllDay', { mode, day })
        : this.intl.t(schedulePrefix + 'consecutiveAllDay', { day });
    }
    end = lastConsecutiveOpenDayHours.split('-')[1];
    return mode
      ? this.intl.t(schedulePrefix + 'handoffConsecutiveTime', { mode, end, day })
      : this.intl.t(schedulePrefix + 'consecutiveTime', { end, day });
  }

  // Tasks
  refreshRecentItemsCategoryIfVisible = task(async () => {
    if (this.vendor && (this.maxRecentItems ?? 0) > 0 && this.session.isLoggedIn) {
      try {
        await this.vendor.fetchRecentItemsCategory({
          maxItems: this.maxRecentItems ?? 0,
        });
      } catch (e) {
        // do nothing
      }
    }
  });

  ensureRecentItemsLoaded = () =>
    !this.vendor?.recentItemCategories?.length &&
    this.refreshRecentItemsCategoryIfVisible.perform();

  // Actions and helpers
}

declare module '@ember/service' {
  interface Registry {
    vendor: VendorService;
  }
}

//Moved from PR #1987
function convertToTime(currentTimeWithTZ: string, targetTime: string) {
  //currentTimeWithTZ format: 2020-01-31T11:30:20-04:00
  //                                    |replacing|
  // Dayjs does not have good timezone support and cannot get the start of a day
  // in a certain timezone. It also requires extra plugins to support parsing.
  // So, this returns the Dayjs format of ?X?X:(?xx?)(am/pm) on the day passed in.
  let newTime;
  if (targetTime === ScheduleAvailabilityKeywords.Midnight) {
    newTime = '00:00:00';
  } else {
    const hasMins = targetTime.includes(':');
    const isPm = targetTime.includes('pm');

    // Strips postfix and converts hours to XX format
    const hours = ((Number(targetTime.split(':')[0].replace(/\D/g, '')) % 12) + (isPm ? 12 : 0))
      .toString()
      .padStart(2, '0');
    const mins = hasMins ? targetTime.split(':')[1].substring(0, 2) : '00';
    newTime = hours + ':' + mins + ':00';
  }
  return currentTimeWithTZ.substring(0, 11) + newTime + currentTimeWithTZ.substring(19);
}

//Moved from PR #1987
function midnightToLowerCase(time: string) {
  return time.replace('Midnight', 'midnight');
}
